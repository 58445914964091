import React from 'react';

function Profile({ profile }) {
  return (
    <div className='profile'>
      {profile.headerBackground && (
        <div className='header' style={{ backgroundImage: `url(${profile.headerBackground})` }} />
      )}
      <img src={profile.photo} alt={profile.name} className='profile-image' />
      <div className='profile-content'>
        <div className='profile-header'>
          <h1 className='profile-title'>{profile.name}</h1>
          <svg className='icon' width='32' height='32'>
            <use href='#icon-checkmark'></use>
          </svg>
        </div>
        <p>{profile.description}</p>
      </div>
    </div>
  );
}

export default Profile;
