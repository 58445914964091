import React from 'react';

function LanguageSwitcher({ languages, currentLanguage, onLanguageChange }) {
  return (
    <div className='language-switcher'>
      {languages.map(lang => (
        <button
          key={lang}
          className={`language-button ${currentLanguage === lang ? 'active' : ''}`}
          onClick={() => onLanguageChange(lang)}
        >
          {lang.toUpperCase()}
        </button>
      ))}
    </div>
  );
}

export default LanguageSwitcher;
