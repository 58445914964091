import React, { useState } from 'react';

function SocialLinks({ modelLinks, tokenLinks, currentLanguage, data }) {
  const [activeTab, setActiveTab] = useState('model');

  const hasModelLinks =
    modelLinks && (modelLinks.favoriteLinks?.length > 0 || modelLinks.socialLinks?.length > 0);
  const hasTokenLinks =
    tokenLinks && (tokenLinks.favoriteLinks?.length > 0 || tokenLinks.socialLinks?.length > 0);
  const showTabSwitcher = hasModelLinks && hasTokenLinks;

  const renderLink = ({ name, url, icon, text }) => (
    <a href={url} key={url} className='social-card' rel='noreferrer' target='_blank'>
      <div className='social-icon'>
        <img src={icon} alt={name} />
      </div>
      <div className='social-body'>
        <div className='social-title'>{name}</div>
        {text && <div className='social-text'>{text}</div>}
      </div>
      <div className='arrow-icon'>
        <svg width='32' height='32'>
          <use href='#icon-arrow'></use>
        </svg>
      </div>
    </a>
  );

  const renderLinks = links => links?.filter(Boolean).map(renderLink);

  return (
    <div className='social-list'>
      {showTabSwitcher && (
        <div className='tabs'>
          {hasModelLinks && (
            <button
              className={`btn ${activeTab === 'model' ? 'active' : ''}`}
              onClick={() => setActiveTab('model')}
            >
              Model
            </button>
          )}
          {hasTokenLinks && (
            <button
              className={`btn ${activeTab === 'token' ? 'active' : ''}`}
              onClick={() => setActiveTab('token')}
            >
              Token
            </button>
          )}
        </div>
      )}

      {activeTab === 'model' && hasModelLinks && (
        <div className='social-list'>
          <div className='social-list__top'>{renderLinks(modelLinks.favoriteLinks)}</div>
          {renderLinks(modelLinks.socialLinks)}
        </div>
      )}

      {activeTab === 'token' && hasTokenLinks && (
        <div className='social-list'>
          <div className='social-list__top'>{renderLinks(tokenLinks.favoriteLinks)}</div>
          {renderLinks(tokenLinks.socialLinks)}
        </div>
      )}

      {!showTabSwitcher && !hasModelLinks && !hasTokenLinks && <p>No links available</p>}
    </div>
  );
}

export default SocialLinks;
