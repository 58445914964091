import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import LanguageSwitcher from './components/LanguageSwitcher';
import Profile from './components/Profile';
import SocialLinks from './components/SocialLinks';
import './index.css';

function App() {
  const [data, setData] = useState(null);
  const [currentLanguage, setCurrentLanguage] = useState(null);

  useEffect(() => {
    fetch('/data/data.json')
      .then(res => res.json())
      .then(jsonData => {
        setData(jsonData);
        const savedLanguage = localStorage.getItem('language');
        const defaultLanguage = savedLanguage || Object.keys(jsonData)[0];
        setCurrentLanguage(defaultLanguage);
      })
      .catch(err => console.error('Error loading JSON:', err));
  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }

  const handleLanguageChange = language => {
    setCurrentLanguage(language);
    localStorage.setItem('language', language);
  };

  return (
    <div
      className={`app ${window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : ''}`}
    >
      <Helmet>
        <title>{data[currentLanguage]?.profile.name}</title>
        <meta name='description' content={data[currentLanguage]?.profile.description} />
      </Helmet>
      {/* Определение иконок */}
      <svg xmlns='http://www.w3.org/2000/svg' style={{ display: 'none' }}>
        <symbol id='icon-arrow' viewBox='0 0 32 32'>
          <rect width='32' height='32' rx='16' fill='white' fillOpacity='0.04' />
          <path
            d='M19.6875 15.3125C20.0938 15.6875 20.0938 16.3438 19.6875 16.7188L14.6875 21.7188C14.3125 22.125 13.6562 22.125 13.2812 21.7188C12.875 21.3438 12.875 20.6875 13.2812 20.3125L17.5625 16L13.2812 11.7188C12.875 11.3438 12.875 10.6875 13.2812 10.3125C13.6562 9.90625 14.3125 9.90625 14.6875 10.3125L19.6875 15.3125Z'
            fill='#BCBCBC'
          />
        </symbol>
        <symbol id='icon-checkmark' viewBox='0 0 22 22'>
          <path
            d='M20.396 11c-.018-.646-.215-1.275-.57-1.816-.354-.54-.852-.972-1.438-1.246.223-.607.27-1.264.14-1.897-.131-.634-.437-1.218-.882-1.687-.47-.445-1.053-.75-1.687-.882-.633-.13-1.29-.083-1.897.14-.273-.587-.704-1.086-1.245-1.44S11.647 1.62 11 1.604c-.646.017-1.273.213-1.813.568s-.969.854-1.24 1.44c-.608-.223-1.267-.272-1.902-.14-.635.13-1.22.436-1.69.882-.445.47-.749 1.055-.878 1.688-.13.633-.08 1.29.144 1.896-.587.274-1.087.705-1.443 1.245-.356.54-.555 1.17-.574 1.817.02.647.218 1.276.574 1.817.356.54.856.972 1.443 1.245-.224.606-.274 1.263-.144 1.896.13.634.433 1.218.877 1.688.47.443 1.054.747 1.687.878.633.132 1.29.084 1.897-.136.274.586.705 1.084 1.246 1.439.54.354 1.17.551 1.816.569.647-.016 1.276-.213 1.817-.567s.972-.854 1.245-1.44c.604.239 1.266.296 1.903.164.636-.132 1.22-.447 1.68-.907.46-.46.776-1.044.908-1.681s.075-1.299-.165-1.903c.586-.274 1.084-.705 1.439-1.246.354-.54.551-1.17.569-1.816zM9.662 14.85l-3.429-3.428 1.293-1.302 2.072 2.072 4.4-4.794 1.347 1.246z'
            fill='#1d9bf0'
          ></path>
        </symbol>
      </svg>

      {Object.keys(data).length > 1 && (
        <LanguageSwitcher
          languages={Object.keys(data)}
          currentLanguage={currentLanguage}
          onLanguageChange={handleLanguageChange}
        />
      )}
      <Profile profile={data[currentLanguage].profile} />
      <SocialLinks
        modelLinks={data[currentLanguage].modelLinks}
        tokenLinks={data[currentLanguage].tokenLinks}
        currentLanguage={currentLanguage}
        data={data}
      />
    </div>
  );
}

export default App;
